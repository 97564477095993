var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.tabs.length > 1)?_c('div',{staticClass:"card--stats-toggle"},[_c('tabs-fake',{staticClass:"tabs--rounded",attrs:{"children":_vm.tabs,"defaultSelectedId":_vm.tabs[0].id,"gaId":_vm.gaId},on:{"click:tab":_vm.updateDatabaseId}})],1):_vm._e(),_vm._v(" "),(_vm.hasCoverageStats)?_c('div',{staticClass:"card--stats-wrapper"},_vm._l((_vm.activeDatabase.coverage),function(stat){return _c('stats-coverage',_vm._b({key:stat._uid},'stats-coverage',{
        nationalReportVersion: stat.national_report_version,
        pameKm2: stat.pame_km2,
        pamePercentage: stat.pame_percentage,
        protectedKm2: stat.protected_km2,
        protectedNationalReport: stat.protected_national_report,
        protectedPercentage: stat.protected_percentage,
        textCoverage: stat.text_coverage,
        textNationalReport: stat.text_national_report,
        textPame: stat.text_pame,
        textPameAssessments: stat.text_pame_assessments,
        textProtected: stat.text_protected,
        textTotal: stat.text_total,
        title: stat.title,
        totalKm2: stat.total_km2,
        type: stat.type,
      },false))}),1):_vm._e(),_vm._v(" "),_c('stats-message',_vm._b({},'stats-message',{
      documents: _vm.activeDatabase.message.documents,
      link: _vm.activeDatabase.message.link,
      text: _vm.activeDatabase.message.text
    },false)),_vm._v(" "),_c('div',{staticClass:"card--stats-wrapper pdf-break-before"},[(_vm.hasIucnCategories)?_c('stats-iucn-categories',_vm._b({},'stats-iucn-categories',{
        categories: _vm.activeDatabase.iucn.categories,
        chart: _vm.activeDatabase.iucn.chart,
        title: _vm.activeDatabase.iucn.title
      },false)):_vm._e(),_vm._v(" "),(_vm.hasGovernanceTypes)?_c('stats-governance',_vm._b({},'stats-governance',{
        governance: _vm.activeDatabase.governance.governance,
        chart: _vm.activeDatabase.governance.chart,
        title: _vm.activeDatabase.governance.title
      },false)):_vm._e()],1),_vm._v(" "),(_vm.hasSources)?_c('stats-sources',_vm._b({},'stats-sources',{
      count: _vm.activeDatabase.sources.count,
      title: _vm.activeDatabase.sources.title,
      sourceUpdated: _vm.activeDatabase.sources.source_updated,
      sources: _vm.activeDatabase.sources.sources
    },false)):_vm._e(),_vm._v(" "),(_vm.hasDesignations)?_c('stats-designations',_vm._b({},'stats-designations',{
      chart: _vm.activeDatabase.designations.chart,
      designations: _vm.activeDatabase.designations.designations,
      title: _vm.activeDatabase.designations.title,
    },false)):_vm._e(),_vm._v(" "),_vm._t("related_countries"),_vm._v(" "),(_vm.hasSites)?_c('stats-sites',_vm._b({},'stats-sites',{
      siteDetails: _vm.activeDatabase.sites.site_details,
      textViewAll: _vm.activeDatabase.sites.text_view_all,
      title: _vm.activeDatabase.sites.title,
      viewAll: _vm.activeDatabase.sites.view_all 
    },false)):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }